import React from 'react';

import styles from '../styles.module.scss';

interface Props {
  handleChange: Function;
  formValues: any;
  handleNext: Function;
}

const Step2 = ({ handleChange, handleNext, formValues }: Props) => {
  return (
    <div>
      <form onSubmit={() => handleNext()}>
      <div className={styles.inputContainer}>
        <h3>Child Enrolement</h3>
        <div className={styles.inner}>
          <label>
            First Name
            <input
              name="childFirstName"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.childFirstName || ''}
              required
            />
          </label>
          <label>
            Last Name
            <input
              name="childLastName"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.childLastName || ''}
              required
            />
          </label>
          <label>
            Preferred Name
            <input
              name="childPreferredName"
              type="text"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.childPrefferedName || ''}
              required
            />
          </label>
          <label>
            Gender
            <div className={styles.radioGroup}>
              <label>
                Male
                <input type="radio" name="childGender" value="male" onChange={(e) => handleChange(e)} required />
              </label>
              <label>
                Female
                <input type="radio" name="childGender" value="female" onChange={(e) => handleChange(e)} required />
              </label>
            </div>
          </label>
          <label>
            Date of birth
            <input
              type="date"
              name="childDOB"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.ChildDOB|| ''}
              required
            />
          </label>
          <label>
            Home Address
            <input
              name="child1Address"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.childAddress|| ''}
              required
            />
          </label>
          <label>
            Country of Birth
            <input
              name="child1BirthCountry"
              onChange={(e) => handleChange(e)}
              value={formValues && formValues.childBirthCountry|| ''}
              required
            />
          </label>
          <label>
            Primary spoken language
            <input type="text" name="childSpokenLanguage" onChange={(e) => handleChange(e)} required/>
          </label>
          <label>
            Cultural Background
            <input type="text" name="childCulturalBackground" onChange={(e) => handleChange(e)} required/>
          </label>
          <label>
            Religion (optional)
            <input type="text" name="childReligion" onChange={(e) => handleChange(e)} required/>
          </label>
        </div>
        <button type="submit" className={styles.nextBtn}>Next</button>
      </div>
      </form>
    </div>
  )
}
export default Step2;
